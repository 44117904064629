<template>
  <div class="page-box">
    <div class="pf-detail">
      <div class="title">
        <span class="label">标题：</span>
        <router-link target="_blank" :to="{name: 'paper', params: {id: pmid}}">{{title}}</router-link>
        <img class="copy-icon" src="../../assets/imgs/copy.png" title="复制标题" @click.stop="onCopyTitle">
        <img class="copy-icon" src="../../assets/imgs/copylink.png" title="复制标题和链接" @click.stop="onCopyTitleLink">
      </div>
      <div class="pmid" v-if="pmid">
        <span class="label">PMID：</span>
        {{pmid}}
      </div>
      <div class="doi" v-if="doi">
        <span class="label">DOI：</span>
        {{doi}}
      </div>
      <div :class="['status', status]">
        <span class="label">状态：</span>
        {{mapStatus(status)}}
        <span v-if="failedDesc && status==='failed'">({{failedDesc}})</span>
      </div>
      <div class="time">
        <span class="label">求助时间：</span>
        {{newTime}}
      </div>
      <div class="help">
        <div class="help-btn" v-if="(status==='new' || status==='failed') && authUnionid!==unionid" @click="handleHelping">应助</div>
      </div>
      <div class="pf-links">
        <div v-if="fileId" class="pdf-link-box">
          <span class="label">原文链接：</span>
          <router-link v-if="pmid" target="_blank" class="pdf-link" :to="{name: 'helppdf', params: {id: pmid}, query: {id: id}}">&nbsp;</router-link>
          <router-link v-else target="_blank" class="pdf-link" :to="{name: 'helppdf2', params: {id: fileId}, query: {id: id}}">&nbsp;</router-link>
          <!-- <a target="_blank" class="pdf-link" :href="`${httpRoot}/file/${fileId}`">&nbsp;</a> -->
          <div class="pdf-link-mask" v-if="fileId && authUnionid!==helpUid && authUnionid!==unionid" @click="handlePdfMask"></div>
        </div>
        <div v-else-if="authUnionid===helpUid" class="pdf-upload">
          <span class="label">上传PDF</span>
          <input class="change" ref="file" type="file" @change="onFileChange" @click="loginValid"/>
          <span class="upload" :class="[uploadStatus]">{{uploadStatus==='success'?'文件上传成功':uploadStatus==='fail'?uploadErr:''}}</span>
        </div>
      </div>
      <div class="help-user" v-if="helpUid">
        <span class="label">应助者：</span>
        <img :src="helpHeadimgurl" :title="helpNickname">
      </div>
      <div v-if="authUnionid===unionid">
        <div class="h-result">
          <span class="label requrie">应助确认：</span>
          <span class="btn" @click="helpSuccess" v-if="status==='upload'">成功</span>
          <span class="btn" @click="helpFailed" v-if="status==='upload'">失败</span>
          <span class="btn" @click="helpRenew" v-if="status==='helping'">重新求助</span>
        </div>
      </div>
    </div>
    <help-failed-win :show="showFailWin" @on-hide="showFailWin=false" @on-ok="onFailed"></help-failed-win>
    <login-tip-win2 :show="showLoginTipWin" @on-hide="showLoginTipWin=false" text="微信扫码登陆后可操作"></login-tip-win2>
    <tooltip-win :show="showTipWin" @on-hide="showTipWin=false" :tipType="tipType" :duration="duration" :text="tipText"></tooltip-win>
    <loading-circle2 v-show="showLoadingCircle"></loading-circle2>
  </div>
</template>
<script>
import HelpFailedWin from './help-failed-win'
import LoginTipWin2 from '../login-tip-win/login-tip-win2'
import TooltipWin from '../login-tip-win/tooltip-win'
import LoadingCircle2 from '../common/loading/loading-circle2'
import Mixin from './mixin'
import Login from '../../utils/login'
import { getStorageItem, updateStorageItem } from '../../utils/cache'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: {HelpFailedWin, LoginTipWin2, TooltipWin, LoadingCircle2},
  mixins: [Mixin, Login],
  data () {
    return {
      showLoginTipWin: false,
      authUnionid: '',

      id: '',
      status: '',  // new, helping, upload, success, failed, 求助中，应助中，已上传，应助成功，应助失败（求助者可以确认成功，确认失败，和取消，重新求助，）
      newTime: '',
      helpingTime: '',
      uploadTime: '',
      successTime: '',
      failedTime: '',
      title: '',
      pmid: '',
      doi: '',
      failedDesc: '',
      fileId: '',
      unionid: '',
      helpUid: '',

      uploadStatus: '',
      uploadErr: '',
      showFailWin: false,

      helpNickname: '',
      helpHeadimgurl: '',

      showTipWin: false,
      tipType: '',
      duration: '',
      tipText: '',
      showLoadingCircle: false
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.params && to.params.id) {
      document.title = '文献互助详情'
      next(vm => {
        vm.id = to.params.id
        vm.loadPF()
      })
    } else {
      document.title = '文献互助详情'
      next()
    }
  },
  computed: {
    loggedIn () {
      let auth = getStorageItem('auth')
      let loggedAuth = this.$store.state.auth.loggedAuth
      let isLogin = this.$store.getters.getLoginState
      return auth && loggedAuth && isLogin
    }
  },
  created () {
    this.$on('on-file-upload', () => {
      this.loadPF()
    })
    this.getAuthUnionid()
  },
  methods: {
    loginValid (e) {
      if (!this.loggedIn) {
        e.preventDefault()
        updateStorageItem('route', {name: 'fullhelp'})
        this.showLoginTipWin = true
        return false
      } else {
        return true
      }
    },
    loadPF () {
      this.$http.get(`${this.httpRoot}/paper/fulltext/detail`, authenticate({params: {id: this.id}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && res.fulltext) {
          this.status = res.fulltext.status || ''
          this.title = res.fulltext.title || ''
          this.pmid = res.fulltext.pmid || ''
          this.doi = res.fulltext.doi || ''
          this.fileId = res.fulltext.fileId || ''
          this.url = res.fulltext.url || ''
          this.unionid = res.fulltext.unionid || ''
          this.helpUid = res.fulltext.helpUid || ''
          this.failedDesc = res.fulltext.failedDesc || ''
          this.newTime = res.fulltext.newTime || ''
          this.helpingTime = res.fulltext.helpingTime || ''
          this.uploadTime = res.fulltext.uploadTime || ''
          this.successTime = res.fulltext.successTime || ''
          this.failedTime = res.fulltext.failedTime || ''
          this.helpNickname = res.fulltext.helpNickname || ''
          this.helpHeadimgurl = res.fulltext.helpHeadimgurl || ''
        }
      })
      .catch(err => {
        if (err.status === 401) {
          // 解决没有登录且没有临时token的情况
          this.authUnionid = 'no login'
        } else {
          catchExpired(err, this)
        }
      })
    },
    getAuthUnionid () {
      this.$http.get(`${this.httpRoot}/auth/unionid`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.authUnionid = res.unionid || 'me'
        }
      })
      .catch(err => {
        if (err.status === 401) {
          // 解决没有登录且没有临时token的情况
          this.authUnionid = 'no login'
        } else {
          catchExpired(err, this)
        }
      })
    },
    helpSuccess () {
      this.$http.post(`${this.httpRoot}/paper/fulltext/success`, {id: this.id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.loadPF()
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    helpFailed () {
      this.showFailWin = true
    },
    helpRenew () {
      this.$http.post(`${this.httpRoot}/paper/fulltext/renew`, {id: this.id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.loadPF()
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onFailed (desc) {
      this.$http.post(`${this.httpRoot}/paper/fulltext/failed`, {id: this.id, failedDesc: desc}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.loadPF()
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    handleHelping (e) {
      if (!this.loginValid(e)) return
      this.$http.post(`${this.httpRoot}/paper/fulltext/helping`, {id: this.id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.loadPF()
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onCopyTitle () {
      let input = document.createElement('input')
      input.setAttribute('value', this.title)
      input.style.position = 'absolute'
      input.style.left = '-9999px'
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      setTimeout(() => {
        document.body.removeChild(input)
      }, 1000)
    },
    onCopyTitleLink () {
      let input = document.createElement('input')
      input.setAttribute('value', '应助文献领取奖品：\n' + this.title + ' ' + document.location.href)
      input.style.position = 'absolute'
      input.style.left = '-9999px'
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      setTimeout(() => {
        document.body.removeChild(input)
      }, 1000)
    },
    needFailedDesc () {
      this.tipType = 'warning'
      this.duration = 1000
      this.tipText = '填写失败原因'
      this.showTipWin = true
    },
    handlePdfMask () {
      this.tipType = 'warning'
      this.duration = 1500
      this.tipText = '只有求助者和应助者才可以查看'
      this.showTipWin = true
    }
  }
}
</script>
<style lang="less">
.pf-detail {
  margin-top: 10px;
  font-size: 14px;
  &>div {
    line-height: 22px;
  }
  .label {
    color: #394857;
    &.requrie {
      position: relative;
      &::before {
        content: "*";
        color: red;
        position: absolute;
        display: block;
        top: 0;
        left: -5px;
      }
    }
  }
  .title {
    color: #3f5d94;
    a {
      color: #3f5d94;
    }
    .copy-icon {
      width: 13px;
      height: 13px;
      cursor: pointer;
    }
  }
  .status {
    &.new {
      color: #d82424;
    }
    &.success {
      color: #2e8540;
    }
    &.failed {
      color: #981a1a;
    }
  }
}
.help-btn {
  padding: 2px 5px;
  display: inline-block;
  background-color: #7ba0e4;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: #ffe8e8;
  }
}
.pf-links {
  .pdf-link {
    display: inline-block;
    width: 2rem;
    height: 1.3rem;
    text-align: center;
    background: url(../../assets/imgs/pdf.png) no-repeat center/contain;
  }
  .pdf-upload {
    position: relative;
    .label {
      padding: 3px 5px;
      background-color: #7ba0e4;
      color: #fff;
      cursor: pointer;
      &:hover {
        color: #ffe8e8;
      }
    }
    .change {
      position: absolute;
      overflow: hidden;
      left: 0;
      top: 0;
      opacity: 0;
    }
    .success {
      color: green;
    }
    .fail {
      color: red;
    }
  }
  .pdf-link-box {
    position: relative;
    .pdf-link-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
    }
  }
}
.h-result {
  .btn {
    padding: 2px 5px;
    margin: 0 5px;
    background-color: #7ba0e4;
    color: #fff;
    cursor: pointer;
    &:hover {
      color: #ffe8e8;
    }
  }
}
.help-user {
  position: relative;
  img {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 58px;
  }
}
</style>
